import { ImpactNodeType } from "../model/ImpactType"
import { ImpactType } from "social-supermarket-model"

export const multipleFromGatsby = (gatsbyImpacts: ImpactNodeType[]): ImpactType[] => {
  return gatsbyImpacts ? gatsbyImpacts.map(singleFromGatsby) : undefined
}

export const singleFromGatsby = (gatsbyNodeImpact: ImpactNodeType): ImpactType => {
  return {
    id: gatsbyNodeImpact.id,
    slug: gatsbyNodeImpact.slug,
    name: gatsbyNodeImpact.name,
    iconSvg: gatsbyNodeImpact.icon?.iconSvg,
    plainIconSvg: gatsbyNodeImpact.icon?.plainIconSvg,
  }
}
